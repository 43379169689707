import React from "react";
import ManageSettingsTab from "../../../components/settings/manage_settings/manage_settings_tab";

function index() {
	return (
		<div className="mx-20">
			<ManageSettingsTab name="Manage Settings" />
		</div>
	);
}

export default index;
