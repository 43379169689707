import React from "react";

const Student = () => {
  return (
    <div className="tex text-black">
      indexindexindexindexindexindexindexindexindexindexindexindexindexindexindexindexindex
    </div>
  );
};

export default Student;
