export const permissionsTab = [
  { id: 1, name: "Admin Dashboard" },
  { id: 2, name: "Employee" },
  { id: 3, name: "Engagement" },
  { id: 4, name: "Leave" },
  { id: 5, name: "Finance" },
  { id: 6, name: "Recruitment" },
  { id: 7, name: "Request" },
  { id: 8, name: "Performance" },
];
