import React from "react";

const Resume = () => {
  return (
    <div>
      <div className="h-16 w-full bg-main"></div>
    </div>
  );
};

export default Resume;
