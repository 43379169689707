import React from "react";

const SubjectDeleted = () => {
  return (
    <div>
      <p></p>
    </div>
  );
};

export default SubjectDeleted;
