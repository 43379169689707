export const jobdata = [
  {
    id: 1,
    name: "New Applied",

    amount: "10",
    candidate: [
      {
        photo: "https://www.famousbirthdays.com/faces/zafeer-humna-image.jpg",
        firstName: "Alice",
        lastName: "Smith",
        applicationDate: "2023-08-23",
      },
      {
        photo: "https://www.example.com/user2.jpg",
        firstName: "Bob",
        lastName: "Johnson",
        applicationDate: "2023-08-24",
      },
      {
        photo: "https://www.example.com/user3.jpg",
        firstName: "Eve",
        lastName: "Brown",
        applicationDate: "2023-08-25",
      },
      {
        photo: "https://www.example.com/user4.jpg",
        firstName: "David",
        lastName: "Miller",
        applicationDate: "2023-08-26",
      },
      {
        photo: "https://www.example.com/user5.jpg",
        firstName: "Sophia",
        lastName: "Williams",
        applicationDate: "2023-08-27",
      },
    ],
  },

  {
    id: 2,
    name: "Screened",
    amount: "10",
    candidate: [
      {
        photo: "https://www.example.com/user1.jpg",
        firstName: "Alice",
        lastName: "Smith",
        applicationDate: "2023-08-23",
      },
      {
        photo: "https://www.example.com/user2.jpg",
        firstName: "Bob",
        lastName: "Johnson",
        applicationDate: "2023-08-24",
      },
      {
        photo: "https://www.example.com/user3.jpg",
        firstName: "Eve",
        lastName: "Brown",
        applicationDate: "2023-08-25",
      },
      {
        photo: "https://www.example.com/user4.jpg",
        firstName: "David",
        lastName: "Miller",
        applicationDate: "2023-08-26",
      },
      {
        photo: "https://www.example.com/user5.jpg",
        firstName: "Sophia",
        lastName: "Williams",
        applicationDate: "2023-08-27",
      },
    ],
  },

  {
    id: 3,
    name: "Test completed",
    amount: "10",
    candidate: [
      {
        photo: "https://www.example.com/user1.jpg",
        firstName: "Alice",
        lastName: "Smith",
        applicationDate: "2023-08-23",
      },
      {
        photo: "https://www.example.com/user2.jpg",
        firstName: "Bob",
        lastName: "Johnson",
        applicationDate: "2023-08-24",
      },
      {
        photo: "https://www.example.com/user3.jpg",
        firstName: "Eve",
        lastName: "Brown",
        applicationDate: "2023-08-25",
      },
      {
        photo: "https://www.example.com/user4.jpg",
        firstName: "David",
        lastName: "Miller",
        applicationDate: "2023-08-26",
      },
      {
        photo: "https://www.example.com/user5.jpg",
        firstName: "Sophia",
        lastName: "Williams",
        applicationDate: "2023-08-27",
      },
    ],
  },

  {
    id: 4,
    name: "Interviewed",
    amount: "10",
    candidate: [
      {
        photo: "https://www.example.com/user1.jpg",
        firstName: "Alice",
        lastName: "Smith",
        applicationDate: "2023-08-23",
      },
      {
        photo: "https://www.example.com/user2.jpg",
        firstName: "Bob",
        lastName: "Johnson",
        applicationDate: "2023-08-24",
      },
      {
        photo: "https://www.example.com/user3.jpg",
        firstName: "Eve",
        lastName: "Brown",
        applicationDate: "2023-08-25",
      },
      {
        photo: "https://www.example.com/user4.jpg",
        firstName: "David",
        lastName: "Miller",
        applicationDate: "2023-08-26",
      },
      {
        photo: "https://www.example.com/user5.jpg",
        firstName: "Sophia",
        lastName: "Williams",
        applicationDate: "2023-08-27",
      },
    ],
  },

  {
    id: 5,
    name: "Design challenged",
    amount: "10",
    candidate: [
      {
        photo: "https://www.example.com/user1.jpg",
        firstName: "Alice",
        lastName: "Smith",
        applicationDate: "2023-08-23",
      },
      {
        photo: "https://www.example.com/user2.jpg",
        firstName: "Bob",
        lastName: "Johnson",
        applicationDate: "2023-08-24",
      },
      {
        photo: "https://www.example.com/user3.jpg",
        firstName: "Eve",
        lastName: "Brown",
        applicationDate: "2023-08-25",
      },
      {
        photo: "https://www.example.com/user4.jpg",
        firstName: "David",
        lastName: "Miller",
        applicationDate: "2023-08-26",
      },
      {
        photo: "https://www.example.com/user5.jpg",
        firstName: "Sophia",
        lastName: "Williams",
        applicationDate: "2023-08-27",
      },
    ],
  },
  {
    id: 6,
    name: "Hired",
    amount: "10",
    candidate: [
      {
        photo: "https://www.example.com/user1.jpg",
        firstName: "Alice",
        lastName: "Smith",
        applicationDate: "2023-08-23",
      },
      {
        photo: "https://www.example.com/user2.jpg",
        firstName: "Bob",
        lastName: "Johnson",
        applicationDate: "2023-08-24",
      },
      {
        photo: "https://www.example.com/user3.jpg",
        firstName: "Eve",
        lastName: "Brown",
        applicationDate: "2023-08-25",
      },
      {
        photo: "https://www.example.com/user4.jpg",
        firstName: "David",
        lastName: "Miller",
        applicationDate: "2023-08-26",
      },
      {
        photo: "https://www.example.com/user5.jpg",
        firstName: "Sophia",
        lastName: "Williams",
        applicationDate: "2023-08-27",
      },
    ],
  },
];
